<template>
    <BModal
        :id="id"
        :ref="id"
        :visible="visible"
        :size="size"
        :scrollable="scrollable"
        :centered="centered"
        :hide-header="hideHeader"
        :hide-footer="hideFooter"
        :no-close-on-esc="loading"
        :no-close-on-backdrop="loading"
        @hidden="handleHidden"
        @shown="handleShown"
    >
        <IconButton
            v-if="hideHeader && cancelable"
            icon="CrossCircle"
            variant=""
            class="position-absolute close-button"
            @click="close"
        />

        <template #modal-header>
            <h5 class="modal-title">{{ title }}</h5>
            <IconButton v-if="cancelable" icon="CrossCircle" variant="" class="close-button" @click="close" />
        </template>

        <slot />
    </BModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { BModal } from 'bootstrap-vue'
import IconButton from '../buttons/IconButton.vue'
import { modalSizeOptions } from '../../mocks/StorybookOptions.mock'

export default defineComponent({
    name: 'Modal',
    components: {
        BModal,
        IconButton,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
        size: {
            type: String,
            default: null,
            validator: (value: string) => modalSizeOptions.includes(value),
        },
        modalClass: {
            type: String,
            default: null,
        },
        scrollable: {
            type: Boolean,
            default: false,
        },
        centered: {
            type: Boolean,
            default: true,
        },
        hideHeader: {
            type: Boolean,
            default: true,
        },
        hideFooter: {
            type: Boolean,
            default: true,
        },
        cancelable: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ref() {
            return this.$refs[this.id] as BModal
        },
    },
    methods: {
        close() {
            this.ref.hide()
        },
        handleHidden() {
            this.$emit('hidden')
        },
        handleShown() {
            this.$emit('shown')
        },
    },
})
</script>

<style scoped lang="scss">
.close-button {
    top: 0px;
    right: 0px;

    .modal-header & {
        padding: 1rem 1rem;
        margin: -1rem -1rem -1rem auto;
    }
}
</style>

<style>
.modal.fade,
.modal.fade .modal-dialog,
.modal.show .modal-dialog {
    transition: none !important;
    transform: none !important;
    transition-delay: 0ms;
    transition-duration: 0ms;
}

.modal-backdrop {
    opacity: 0.5;
}

.modal-dialog.modal-full {
    max-width: 100%;
    padding: 1rem;

    @media (min-width: 1200px) {
        max-width: 1140px;
    }
}
</style>
