<template>
    <div class="row justify-content-center">
        <div class="col-md-10">
            <div v-if="!user.has_plan">
                <div v-if="plan">
                    <template v-if="freeTrialPeriod">
                        <p class="font-weight-bold text-center mb-0">30-Day Free Trial</p>
                        <p class="f-14 text-muted text-center">
                            Time to explore! Try My Worksheet Maker free for 30 days.
                            <br />
                            Your subscription automatically begins after the trial ends.
                        </p>

                        <Notice icon="InfoCircle" text-align="left">
                            <p class="mb-0">
                                After your 30 day trial, you will be charged ${{ subscriptionCost }}/{{
                                    plan.interval === 'year' ? 'year' : 'month'
                                }}. Cancel anytime.
                                <br />
                                <em>Your card will NOT be charged at this time.</em>
                            </p>
                        </Notice>
                    </template>

                    <template v-else>
                        <div v-if="planLabel === 'single'">
                            <p class="font-weight-bold text-center mb-0">
                                Purchase Single
                                {{
                                    brandConfig.alias === 'worksheet'
                                        ? 'Document'
                                        : brandConfig.alias === 'flashcard'
                                          ? 'Flashcard Set'
                                          : 'Bingo Set'
                                }}
                            </p>
                            <p class="f-14 text-muted text-center">
                                Great choice! Single
                                {{ brandConfig.alias === 'worksheet' ? 'worksheets' : `${brandConfig.alias} sets` }} are
                                perfect for special, one-time events and parties. The price even includes
                                <strong>one edit</strong>
                                , so you can easily make changes, If needed.
                            </p>
                        </div>

                        <div v-if="planLabel === 'lifetime'">
                            <p class="font-weight-bold text-center mb-0">Lifetime Premium Membership</p>
                            <p class="f-14 text-muted text-center">
                                Unlock Premium Membership on all our sister sites—
                                <strong>for life!</strong>
                                The absolute best value for educators.

                                <span v-if="brandConfig.alias === 'worksheet'">
                                    Create unlimited worksheets,
                                    <a target="_blank" rel="noopener noreferrer" :href="crossword_url">crosswords</a>
                                    ,
                                    <a target="_blank" rel="noopener noreferrer" :href="wordsearch_url">word searches</a>
                                    , bingo, flashcard sets and more.
                                </span>

                                <span v-if="brandConfig.alias === 'flashcard'">
                                    Create unlimited flashcard sets, worksheets,
                                    <a target="_blank" rel="noopener noreferrer" :href="crossword_url">crosswords</a>
                                    ,
                                    <a target="_blank" rel="noopener noreferrer" :href="wordsearch_url">word searches</a>
                                    , bingo and more.
                                </span>

                                <span v-if="brandConfig.alias === 'bingo'">
                                    Create unlimited bingo, flashcard sets, worksheets,
                                    <a target="_blank" rel="noopener noreferrer" :href="crossword_url">crosswords</a>
                                    ,
                                    <a target="_blank" rel="noopener noreferrer" :href="wordsearch_url">word searches</a>
                                    and more.
                                </span>
                            </p>
                        </div>

                        <div v-if="planLabel === 'Basic'">
                            <p v-if="brandConfig.alias === 'worksheet'" class="font-weight-bold text-center mb-0">
                                Basic Membership
                            </p>
                            <p v-else class="font-weight-bold text-center mb-0">
                                Unlimited
                                {{
                                    brandConfig.alias[0].toUpperCase() +
                                    brandConfig.alias.substring(1) +
                                    (brandConfig.alias === 'flashcard' ? 's' : '')
                                }}
                            </p>

                            <div v-if="brandConfig.alias === 'worksheet'">
                                <p class="f-14 text-muted text-center">
                                    Enjoy all fantastic free worksheet options, plus save your worksheets as
                                    <strong>'Private'</strong>
                                    .
                                    <br />
                                    Your worksheets automatically save as you go and are available on your account forever.
                                </p>
                            </div>

                            <div v-if="brandConfig.alias === 'flashcard' || brandConfig.alias === 'bingo'">
                                <p v-if="brandConfig.alias === 'flashcard'" class="f-14 text-muted text-center">
                                    Make unlimited {{ brandConfig.alias }} sets with all features unlocked.
                                    <br />
                                    Unleash your creativity. Your cards stay on your account forever.
                                </p>
                                <p v-else class="f-14 text-muted text-center">
                                    Make unlimited {{ brandConfig.alias }} sets with all features unlocked.
                                    <br />
                                    Unlock every option and keep cards on your account forever.
                                </p>
                            </div>
                        </div>

                        <div v-if="planLabel === 'Standard'">
                            <p class="font-weight-bold text-center mb-0">
                                {{
                                    brandConfig.alias === 'worksheet'
                                        ? 'Standard Membership'
                                        : brandConfig.alias === 'flashcard'
                                          ? 'Flashcards + Bingo'
                                          : 'Bingo + Flashcards'
                                }}
                            </p>

                            <div v-if="brandConfig.alias === 'worksheet'">
                                <p class="f-14 text-muted text-center">
                                    Unlock every single option, style, color, font and formatting choice for fully
                                    customizable worksheets.
                                </p>
                            </div>

                            <div v-if="brandConfig.alias === 'flashcard'">
                                <p class="f-14 text-muted text-center">
                                    Make unlimited flashcards and bingo with all features unlocked.
                                    <br />
                                    Your bingo and flashcards automatically save as you go and are available on your account
                                    forever.
                                </p>
                            </div>

                            <div v-if="brandConfig.alias === 'bingo'">
                                <p class="f-14 text-muted text-center">
                                    Make unlimited bingo and flashcard sets with all features unlocked.
                                    <br />
                                    Your bingo and flashcards automatically save as you go and are available on your account
                                    forever. Awesome!
                                </p>
                            </div>
                        </div>

                        <div v-if="planLabel === 'Premium'">
                            <p class="font-weight-bold text-center mb-0">Premium Membership</p>
                            <p class="f-14 text-muted text-center">
                                The absolute best value for educators.
                                <br />
                                Create unlimited worksheets, crosswords, word searches, bingo, and flashcards.
                            </p>
                        </div>

                        <Notice v-if="plan.recurring">
                            <p class="mb-0">
                                You will be charged ${{ subscriptionCost }}
                                <span v-if="!purchaseIsReactivation">, beginning today</span>
                                <span>.</span>
                                Renews {{ plan.interval === 'year' ? 'annually' : 'monthly' }}. Cancel anytime.
                            </p>
                        </Notice>

                        <Notice v-else>
                            <p class="mb-0">
                                You will be charged ${{ subscriptionAmount(plan.amount) }}.
                                <span v-if="planLabel === 'lifetime'">One-time payment. Membership never expires.</span>
                            </p>
                        </Notice>
                    </template>
                </div>
            </div>

            <div v-if="cards.cards.length" class="mb-2">
                <div class="font-weight-bold text-sm mb-2">Use an existing card:</div>
                <div
                    v-for="(card, index) in cards.cards"
                    :key="'card-' + index"
                    class="mb-2"
                    :class="{ 'has-error': hasError && isActiveCard(card.id) }"
                >
                    <div class="d-flex align-items-center" :class="{ 'is-invalid': hasError && isActiveCard(card.id) }">
                        <b-form-radio
                            v-model="paymentMethod"
                            name="paymentMethod"
                            :value="card.id"
                            @change="closeCardForm"
                        ></b-form-radio>
                        <div class="d-flex align-items-center">
                            <img
                                v-b-tooltip.hover.top="card.brand"
                                :src="'/images/cards/' + card.brand + '.svg'"
                                class="border rounded mr-2"
                                style="width: 30px"
                            />
                            <span class="mr-1 text-sm">************</span>
                            <span class="mr-2 text-sm">{{ card.last4 }}</span>
                        </div>
                    </div>
                    <div v-if="cards.cardError" class="invalid-feedback">
                        {{ cards.cardError }}
                    </div>
                    <div v-else-if="subscriptionError" class="invalid-feedback">
                        {{ subscriptionError }}
                    </div>
                </div>
            </div>

            <b-button v-show="!show_card_form" size="sm" variant="secondary" class="mt-1" @click="showCardForm()">
                <b-icon-plus></b-icon-plus>
                Add Card
            </b-button>
            <div v-show="show_card_form" :class="{ 'd-flex align-items-start mb-3': show_card_form }">
                <b-form-radio
                    v-if="cards.cards.length"
                    v-model="paymentMethod"
                    name="paymentMethod"
                    value="NEW"
                    class="pt-2"
                ></b-form-radio>
                <div class="form-group flex-grow-1 mb-0" :class="{ 'has-error': hasError }">
                    <div ref="card" class="form-control" :class="{ 'is-invalid': hasError }"></div>
                    <div v-if="cards.cardError" class="invalid-feedback">
                        {{ cards.cardError }}
                    </div>
                    <div v-else-if="subscriptionError" class="invalid-feedback">
                        {{ subscriptionError }}
                    </div>
                </div>
                <b-button
                    v-show="cards.cards.length"
                    class="ml-2"
                    variant="secondary"
                    @click="closeCardForm(old_payment_method)"
                >
                    <b-icon-x></b-icon-x>
                </b-button>
            </div>

            <div class="text-center">
                <b-button
                    id="submitCard"
                    :disabled="isLoadingCheckout || invalidCardInfo"
                    :variant="isLoadingCheckout ? 'secondary' : 'success'"
                    class="px-3"
                    @click="purchase()"
                >
                    <Loader v-show="isLoadingCheckout"></Loader>
                    <span
                        :class="{ 'saving ml-2': isLoadingCheckout }"
                        v-text="user.has_plan ? 'Update Card' : 'Submit'"
                    ></span>
                </b-button>

                <p class="font-weight-bold mb-1 mt-3">
                    We offer a 100% satisfaction guarantee
                    <svg
                        height="20"
                        weight="20"
                        class="text-success mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                </p>

                <p class="text-muted f-13">
                    See our
                    <a class="domain-text-color" target="_blank" rel="noopener noreferrer" href="/payments-and-refunds">
                        full payment and refund policies
                    </a>
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import DocumentApi from '../../apis/DocumentApi'
import Loader from '../../widgets/Loader.vue'
import Notice from '../../stories/components/misc/Notice.vue'
import WithCheckout from '../../mixins/WithCheckout'
import wmAlert from '../../helpers/wmAlerts'
import WithGoogleAds from '../../mixins/WithGoogleAds'
import { PlanRecurrence } from '../../objects/Plan'
import UserEvent from '../../objects/UserEvent'

interface Data {
    show_card_form: boolean
    cardError: string
    old_payment_method: string | null
}

export default defineComponent({
    name: 'PaymentDetails',
    components: {
        Loader,
        Notice,
    },
    mixins: [WithCheckout, WithGoogleAds],
    data(): Data {
        return {
            show_card_form: false,
            cardError: '',
            old_payment_method: '',
        }
    },
    computed: {
        cards(): any {
            return this.$store.state.cards as any
        },
        ...mapGetters({
            documentModel: 'document/documentModel',
            defaultCard: 'cards/defaultCard',
            hasValidCardInfo: 'cards/hasValidCardInfo',
            neverHadTrial: 'user/neverHadTrial',
            hasSubscription: 'subscription/hasSubscription',
        }),
        cardElement(): any {
            return this.$refs?.card
        },
        subscriptionCost(): string | number {
            return this.subscriptionAmount(this.plan.amount)
        },
        paymentMethod: {
            get() {
                return !this.cards.cards.length
                    ? 'NEW'
                    : !this.cards.current_card
                      ? this.defaultCard
                      : this.cards.current_card
            },
            async set(value: any) {
                await this.$store.dispatch('cards/setValue', { current_card: value })
            },
        },
        invalidCardInfo(): boolean {
            return this.paymentMethod == 'NEW' && !this.hasValidCardInfo
        },
        purchaseIsSubscription(): boolean {
            return !!this.plan?.recurring
        },
        hasError(): boolean {
            return !!this.cards.cardError || !!this.subscriptionError
        },
        purchaseIsReactivation(): boolean {
            return this.purchaseIsSubscription && this.hasSubscription && this.subscription.stripe_status === 'past_due'
        },
    },
    watch: {
        paymentMethod(newValue, oldValue) {
            if (newValue === 'NEW') {
                this.old_payment_method = oldValue
            }
        },
    },
    async mounted() {
        await this.$store.dispatch('cards/setValue', { show_card_form: false, cardError: '' })
        await this.$store.dispatch('cards/createStripeInput', this.$refs.card)

        if (!this.cards.cards.length) {
            await this.showCardForm()
        }
    },
    methods: {
        // TODO: See why this has a local version of the store state.
        async showCardForm() {
            this.setSubscriptionError(null)
            this.show_card_form = true
            this.paymentMethod = 'NEW'

            if (!this.cards.intent) await this.$store.dispatch('cards/obtainIntent')
        },
        nextCheckoutStep() {
            this.$emit('active-step', 'confetti')
        },
        async closeCardForm(method: string | null = null) {
            this.show_card_form = false
            this.setSubscriptionError(null)
            this.cardError = ''
            await this.$store.dispatch('cards/setValue', { cardError: '', paymentMethodComplete: false })
            if (method) {
                this.paymentMethod = method
                this.old_payment_method = null
            }
        },
        async saveCard() {
            if (!this.cards.intent_response) {
                await this.$store.dispatch('cards/confirmCardSetup')
            }
            await this.$store.dispatch('cards/addCard')
        },
        async giveCardlessTrial() {
            this.setIsLoadingCheckout(true)

            try {
                await this.$store.dispatch('subscription/cardlessTrial', {
                    plan: this.subscription.plans.premium.monthly.id,
                })

                await this.getUser()
            } catch (error) {
                throw error
            } finally {
                this.setIsLoadingCheckout(false)
            }
        },
        async purchase() {
            if (this.invalidCardInfo) {
                return
            }

            if (this.isLoadingCheckout) {
                return
            }

            this.setIsLoadingCheckout(true)
            this.setSubscriptionError(null)

            let card_id = this.paymentMethod

            await this.logPaymentEvent(UserEvent.PAYMENT_SUBMITTED.name)

            if (this.paymentMethod === 'NEW') {
                if (!this.hasValidCardInfo) {
                    this.setIsLoadingCheckout(false)
                    return
                }

                try {
                    await this.saveCard()
                } catch (error) {
                    await this.logPaymentEvent(UserEvent.PAYMENT_FAILED.name, error as string)
                    this.setIsLoadingCheckout(false)
                    throw error
                }

                card_id = this.cards.current_card

                if (!this.cards.paymentMethodComplete) {
                    this.setIsLoadingCheckout(false)
                    return
                }
            }

            await this.logPaymentSubmittion(
                this.plan?.interval,
                this.freeTrialPeriod ? 'free trial' : 'buy now',
                this.planLabel?.toLowerCase(),
                this.page,
            )

            await this.closeCardForm()

            // Get the trialed status of the user before the purchase
            const userCanTrial = !this.user.user.trialed
            const userHasExistingPlan = this.user.user.has_plan

            if (this.purchaseIsReactivation) {
                try {
                    await this.$store.dispatch('subscription/reactivatePlan', {
                        subscription_id: this.subscription.id,
                        method: card_id,
                    })

                    await this.$store.dispatch('subscription/setOneClickUpgrade', {
                        plan: this.plan,
                        subscriptionCycle: this.subscription.one_click_content.plan_frequency,
                        success_feedback: 'Your membership has resumed.',
                    })

                    this.$bvModal.hide('subscription-suspended')
                    this.$bvModal.show('success-modal')
                } catch (error: any) {
                    const message = error?.response?.data?.error || 'There was an error processing your card.'
                    await this.logPaymentEvent(UserEvent.PAYMENT_FAILED.name, message)

                    this.setIntent(null)
                    this.setSubscriptionError(message)

                    throw 'error'
                } finally {
                    this.setIsLoadingCheckout(false)
                }
                return
            }

            if (this.purchaseIsSubscription) {
                try {
                    await this.$store.dispatch('subscription/subscribe', {
                        method: card_id,
                        plan: this.plan.id,
                        trial: this.freeTrialPeriod,
                    })

                    await this.getUser()

                    await this.logPaymentSuccess(
                        this.plan?.interval,
                        this.freeTrialPeriod ? 'free trial' : 'buy now',
                        this.planLabel?.toLowerCase(),
                        this.page,
                    )
                    await this.logPaymentEvent(UserEvent.PAYMENT_SUCCEEDED.name)

                    this.initialiseConversionEvent({
                        price: this.plan.amount,
                        planRecurrence: this.plan.interval as PlanRecurrence,
                        planType: this.selectedPlanType,
                        userCanTrial,
                        userHasExistingPlan,
                        freeTrial: !!this.freeTrialPeriod,
                    })

                    if (!this.oneClickUpgrade.plan) {
                        this.nextCheckoutStep()
                    } else {
                        this.$bvModal.hide('checkout-modal')
                        this.$bvModal.show('success-modal')
                    }
                } catch (error: any) {
                    const message = error?.response?.data?.error || 'There was an error processing your card.'
                    await this.logPaymentEvent(UserEvent.PAYMENT_FAILED.name, message)

                    if (error.response) {
                        this.setIntent(null)
                        this.setSubscriptionError(message)
                        throw 'error'
                    }

                    await wmAlert.confirm({
                        title: 'There was an error publishing this document.',
                        html: 'Check your network status and try again.',
                        confirmButtonText: 'Ok',
                        showDenyButton: false,
                    })

                    throw error
                } finally {
                    this.setIsLoadingCheckout(false)
                }
            }

            if (!this.purchaseIsSubscription) {
                if (this.brandHasSinglePlan && (!this.document.id || !this.document.user_id)) {
                    //save document
                    await this.$store.dispatch('document/initDocumentFilename')

                    let document = this.documentModel

                    await this.$store.dispatch('document/setSaveDocument', true)

                    try {
                        let response = await DocumentApi.create(this.document.entity_type, document)
                        await this.$store.dispatch('document/removeSavedState')
                        await this.$store.dispatch('document/setDocument', {
                            id: response.data.id,
                            user_id: response.data.user_id,
                        })
                        window.history.replaceState({}, '', `/${this.document.entity_type}/${this.document.id}/edit`)
                    } catch (error: any) {
                        if (error.response) {
                            this.setSubscriptionError('Your purchase failed and you were not charged.')
                            this.setProcessingError(
                                'Your purchase attempt failed.  You were not charged, but refreshing should clear this issue.',
                            )
                            await this.$modals.open('error_handling')
                            throw 'error'
                        }

                        await wmAlert.confirm({
                            title: 'There was an error publishing this document.',
                            html: 'Check your network status and try again.',
                            confirmButtonText: 'Ok',
                            showDenyButton: false,
                        })
                        throw error
                    }
                }

                try {
                    await this.$store.dispatch('subscription/purchase', {
                        method: card_id,
                        price: this.plan,
                        document: !this.brandHasSinglePlan ? null : this.document.id,
                    })

                    await this.$store.dispatch('document/resetSinglePurchaseEdits')

                    await this.logPaymentSuccess(
                        this.plan?.interval,
                        this.user.user.trialed ? 'free trial' : 'buy now',
                        this.planLabel?.toLowerCase(),
                        this.page,
                    )
                    await this.logPaymentEvent(UserEvent.PAYMENT_SUCCEEDED.name)

                    await this.getUser()

                    if (this.brandHasSinglePlan) {
                        if (this.neverHadTrial) {
                            try {
                                await this.giveCardlessTrial()

                                this.initialiseConversionEvent({
                                    price: this.plan.amount,
                                    planRecurrence: null,
                                    planType: this.selectedPlanType,
                                    userCanTrial,
                                    userHasExistingPlan,
                                    freeTrial: true,
                                    cardless: true,
                                })

                                if (this.planLabel !== 'lifetime') {
                                    this.$bvModal.hide('checkout-modal')
                                    this.$bvModal.show('onboarding-complete')
                                } else {
                                    this.nextCheckoutStep()
                                }
                            } catch (error) {
                                this.$bvToast.toast('Sorry! an error occured', {
                                    title: 'Success',
                                    autoHideDelay: 2000,
                                    variant: 'danger',
                                    solid: true,
                                })

                                throw error
                            }
                        } else {
                            this.initialiseConversionEvent({
                                price: this.plan.amount,
                                planRecurrence: null,
                                planType: this.selectedPlanType,
                                userCanTrial,
                                userHasExistingPlan,
                                freeTrial: false,
                            })

                            this.nextCheckoutStep()
                        }

                        await this.$store.dispatch('document/fetchDocumentPurchaseStatus')
                    } else {
                        this.initialiseConversionEvent({
                            price: this.plan.amount,
                            planRecurrence: null,
                            planType: this.selectedPlanType,
                            userCanTrial,
                            userHasExistingPlan,
                            freeTrial: false,
                        })

                        this.nextCheckoutStep()
                    }
                } catch (error: any) {
                    const message = error?.response?.data?.error || 'There was an error processing your card.'
                    this.setIntent(null)
                    this.setSubscriptionError(message)

                    await this.logPaymentEvent(UserEvent.PAYMENT_FAILED.name, message)

                    throw error
                } finally {
                    this.setIsLoadingCheckout(false)
                }
            }
        },
        isActiveCard(cardId: string): boolean {
            return this.paymentMethod === cardId
        },
        async logPaymentEvent(userEvent: string, reason: string = '') {
            let action
            switch (userEvent) {
                case UserEvent.PAYMENT_SUBMITTED.name:
                    action = 'Payment submitted'
                    break
                case UserEvent.PAYMENT_SUCCEEDED.name:
                    action = 'Payment succeeded'
                    break
                case UserEvent.PAYMENT_FAILED.name:
                    action = 'Payment failed'
                    break
            }

            if (!action || !userEvent) return

            const message = `${action} for${this.freeTrialPeriod ? ' 30 day free trial of' : ''} ${this.plan?.name || this.planLabel} ${this.purchaseIsSubscription ? `${this.plan.interval === 'year' ? 'yearly' : 'monthly'} plan` : 'purchase'}.${reason.length ? ` Reason: ${reason}` : ''}`
            await this.$logTrackingEvent(userEvent, message)
        },
    },
})
</script>
